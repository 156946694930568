import React, { Component } from 'react';
import { Form, FormGroup, Button, Alert, Label } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { translate } from 'react-i18next';
import Auth from './Auth';
import Organization from '../organization/api/Organization';
import deviceDetector from 'ua-device-detector';
import packageJson from '../../package.json';
import bulldozairLogo from '../theme/img/new-bulldozair-logo.svg';

class LoginComponent extends Component {
    constructor(props) {
        super(props);

        this._notificationSystem = null;
        this.state = {
            email: '',
            password: '',
            errored: false,
        };

        if (window.navigator.language === 'fr') {
            this.props.i18n.changeLanguage('fr');
        } else {
            this.props.i18n.changeLanguage('en');
        }

        this.login = this.login.bind(this);
    }

    async redirectToKeycloak(e) {
        e.preventDefault();
        const authorizationUri = await Auth.getAuthorizationUri();
        window.location = authorizationUri;
    }

    async logout() {
        const keycloakIdToken = Auth.getKeycloakIdToken();
        Auth.logout();
        window.location = `${process.env.REACT_APP_KEYCLOAK_URL}/auth/realms/${
            process.env.REACT_APP_KEYCLOAK_REALM
        }/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURIComponent(
            window.location.origin,
        )}&client_id=${encodeURIComponent(process.env.REACT_APP_KEYCLOAK_CLIENT_ID)}&id_token_hint=${keycloakIdToken}`;
    }

    async login() {
        this.setState({ loading: true, errored: false });
        try {
            const queryParams = new URLSearchParams(this.props.location.search);
            const state = queryParams.get('state');
            const code = queryParams.get('code');
            if (!state || !code) {
                throw new Error('missing_data');
            }
            const deviceInfo = deviceDetector.parseUserAgent(navigator.userAgent);
            const device = {
                name: deviceInfo.browser,
                platform: 'CLIENT_ADMIN_TOOL',
                os: deviceInfo.os,
                app_version: packageJson.version,
                system_version: deviceInfo.os_version,
            };
            try {
                await Auth.login(state, code, device);
                await Auth.getUser();
            } catch (e) {
                this.setState({
                    error: 'login-failed',
                });
                throw e;
            }

            try {
                const organizations = await Organization.get();
                this.props.history.replace(`/${organizations[0].id}/welcome`);
            } catch (e) {
                this.setState({
                    error: 'login-failed-no-organization',
                });
                throw e;
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false, errored: true });
        }
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;

        this.login();
    }

    render() {
        const { t } = this.props;
        const { loading, errored, error } = this.state;

        return (
            <div className="background-bz auth-wrapper">
                <div className="all-wrapper">
                    <div className="auth-box-w">
                        <div className="logo-w mt-2 mb-2" style={{ padding: '12%' }}>
                            <img alt="" style={{ maxWidth: '320px' }} fill="#fffff" src={bulldozairLogo} />
                        </div>
                        <h4 className="auth-header">{t('Login-title')}</h4>
                        <Form onSubmit={this.redirectToKeycloak}>
                            {errored && <Alert color="danger">{t(error)}</Alert>}
                            <div className="buttons-w">
                                <Button color="primary" block disabled={loading}>
                                    {t('Login-login-button')}
                                </Button>
                                <Button onClick={this.logout} color="danger" block disabled={loading}>
                                    {t('Login-logout-button')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default translate('admin')(LoginComponent);
