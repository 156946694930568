import React, { Component } from 'react';

import Auth from '../../../app/Auth';
import Organization from '../../api/Organization';
import svgLoader from '../../../theme/img/loader.svg';

const containerStyle = {
    marginTop: '10rem',
    height: '5rem',
};

class RedirectToFirstOrganization extends Component {
    getOrganizationsAndRedirectToFirst = async () => {
        try {
            const organizations = await Organization.get();
            this.props.history.replace(`/${organizations[0].id}/welcome`);
        } catch (e) {
            console.error(e);
            this.logout();
        }
    };
    async redirectToKeycloak() {
        const authorizationUri = await Auth.getAuthorizationUri();
        window.location = authorizationUri;
    }
    logout = async () => {
        Auth.logout();
        await this.redirectToKeycloak();
    };
    init = async () => {
        // Check if we have a valid token
        if (!Auth.authenticate()) {
            return this.logout();
        }
        // Get organizations
        this.getOrganizationsAndRedirectToFirst();
    };
    componentDidMount() {
        this.init();
    }

    render() {
        return (
            <div className="d-flex justify-content-center" style={containerStyle}>
                <img src={svgLoader} alt="loader" />
            </div>
        );
    }
}

export default RedirectToFirstOrganization;
